import f1logo from '../../assets/images/f1-logo.png';
import './header.css';

function Header({ handleSaveData, triggerFile, canManageFiles }) {
  return (
    <header className="header">
      <img alt="f1-logo" src={f1logo} className={`f1-logo ${canManageFiles ? 'f1-logo-clickable' : ''}`} onClick={triggerFile}/>
      <h1 onClick={handleSaveData} className={`font-wide title ${ canManageFiles ? 'title-clickable' : ''}`}>BUNKER LEAGUE</h1>
    </header>
  )
}

export default Header;
