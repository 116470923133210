import { useEffect, useState } from 'react';
import Classification from './views/classification/Classification';
import Constructors from './views/constructors/Constructors';
import Header from './components/header/header';
import players from './data/players.json';
import racePoints from './data/racePoints.json';
import constructors from './data/constructors.json';
import { defaultRacePoints } from './config/defaultValues';
import readXlsxFile from 'read-excel-file';

import './App.css';

const REACT_APP_ENV_PROD = JSON.parse(process.env.REACT_APP_ENV_PROD);

function App() {
  const [playersWithPoints, setPlayersWithPoints] = useState([]);
  const [constructorsList, setConstructorsList] = useState([]);
  const [racePointsCalc, setRacePointsCalc] = useState({});
  const [pilotView, setPilotView] = useState(true);
  const canManageFiles = !REACT_APP_ENV_PROD;

  useEffect(() => {
    const input = document.getElementById('input');
    setRacePointsCalc(racePoints);
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        let columns;
        const playersPredictions = {};
        const gpPoints = {};
        const racePointsCalculation = { ...racePoints };
        rows.forEach((row, index) => {
          if (index === 0) {
            columns = row.map(field => {
              if (!field) {
                return 'player';
              } else if (field.toLowerCase() === 'vuelta rapida') {
                return 'vueltaRapida';
              } else {
                return field && field.toLowerCase();
              }
            });
          } else {
            row.forEach((field, index) => {
              if (columns[index] === 'player' && row[0]) {
                playersPredictions[field] = {}
              } else if (row[0]) {
                const player = row[0];
                playersPredictions[player][columns[index]] = columns[index] === 'carrera' || columns[index] === 'sprint' ? field && field.split(' ') : field;
              }
            });
          }
        });
        console.log(playersPredictions);
        Object.keys(playersPredictions).forEach((player) => {
          if (player === 'F1') { return; }
          let prediScore;
          if (!playersPredictions[player].carrera && !playersPredictions[player].sprint &&
            !playersPredictions[player].pole && !playersPredictions[player].vueltaRapida) {
            prediScore = 'DNF';
            gpPoints[player] = prediScore;
          } else {
            prediScore = (playersPredictions[player].carrera
              && playersPredictions[player].carrera.filter((pos, index) => pos?.toLowerCase() === (playersPredictions['F1'].carrera && playersPredictions['F1'].carrera[index]?.toLowerCase())))
              || [];
            if (playersPredictions[player].sprint && playersPredictions[player].sprint.length) {
              playersPredictions[player].sprint.forEach((pos, index) => {
                if (pos.toLowerCase() === (playersPredictions['F1'].sprint && playersPredictions['F1'].sprint[index].toLowerCase())) {
                  prediScore.push(pos);
                }
              });
            }
            gpPoints[player] = prediScore.length + 
              (playersPredictions[player]?.pole?.toLowerCase() === playersPredictions['F1']?.pole?.toLowerCase() ? 1 : 0) +
              (playersPredictions[player]?.vueltaRapida?.toLowerCase() === playersPredictions['F1']?.vueltaRapida?.toLowerCase() ? 2 : 0);
          }
        });
        Object.keys(gpPoints).forEach((player) => racePointsCalculation[player] = gpPoints[player]);
        setRacePointsCalc({ ...racePointsCalculation });
        createFile('racePoints.json', { ...racePointsCalculation });
      });
    })
  }, []);

  useEffect(() => {
    const playersWithPointsArray = [];
    const constructorsListArray = [];
    if (Object.keys(racePointsCalc).some(playerPointsKey => racePointsCalc[playerPointsKey] !== null)) {
      Object.keys(racePointsCalc).forEach(playerPointsKey => {
        const currentPlayer = { ...players.find(player => player.player.toLowerCase() === playerPointsKey.toLocaleLowerCase())};
        currentPlayer.racePoints = racePointsCalc[playerPointsKey];
        playersWithPointsArray.push(currentPlayer);
      });
      
      playersWithPointsArray.sort((player, nextPlayer) => {
        const playerRacePoints = (player.racePoints === 'DNF' || !player.racePoints ? 0 : player.racePoints);
        const playerPoints = playerRacePoints + player.points;
        const nextPlayerRacePoints = (nextPlayer.racePoints === 'DNF' || !nextPlayer.racePoints ? 0 : nextPlayer.racePoints);
        const nextPlayerPoints = nextPlayerRacePoints + nextPlayer.points;
        const playersPointsDifference = nextPlayerPoints - playerPoints;
        
        if (playersPointsDifference === 0) {
          if (player.racePoints === 'DNF' || player.racePoints === null) {
            return 1;
          } else if (nextPlayer.racePoints === 'DNF' || nextPlayer.racePoints === null) {
            return -1;
          } else {
            return player.player < nextPlayer.player && -1;
          }
        }
  
        return playersPointsDifference;
      });
    } else {
      players.forEach(player => playersWithPointsArray.push({ ...player, racePoints: null }));
    }
    playersWithPointsArray.forEach(player => {
      const playerRacePoints = player.racePoints === 'DNF' || !player.racePoints ? 0 : player.racePoints;
      let constructor = constructors.find(constructor => constructor?.team?.toLowerCase() === player?.team?.toLowerCase());
      let constructorMapped = constructorsListArray.find(constructor => constructor?.team?.toLowerCase() === player?.team?.toLowerCase());
      if (!constructorMapped) {
        constructor = { ...constructor, points: player.points + playerRacePoints, calculated: player.racePoints !== null };
        constructorsListArray.push(constructor);
      } else {
        constructorMapped.points += player.points + playerRacePoints;
        constructorMapped.calculated = player.racePoints !== null
      }
    });
    constructorsListArray.sort((constructor, nextConstructor) => nextConstructor.points - constructor.points);
    setPlayersWithPoints(playersWithPointsArray);
    setConstructorsList(constructorsListArray);
  }, [racePointsCalc]);

  function handleSaveData() {
    if (!canManageFiles) { return; }
    const timestamp = new Date().toJSON();
    const updatedPlayers = playersWithPoints.map((player, index) => {
      const updatedPlayer = { ...player };
      const updatedPlayerRacePoints = updatedPlayer.racePoints === 'DNF' || updatedPlayer.racePoints === null ? 0 : updatedPlayer.racePoints;
      updatedPlayer.currentPosition = index + 1;
      updatedPlayer.points += updatedPlayerRacePoints;
      delete updatedPlayer.racePoints;
      return updatedPlayer;
    });
    const updatedConstructors = constructorsList.map((constructor, index) => {
      const updatedConstructor = { ...constructor };
      updatedConstructor.currentPosition = index + 1;
      delete updatedConstructor.points;
      delete updatedConstructor.calculated
      return updatedConstructor;
    })
    createFile(`${timestamp}-players.json`, players);
    createFile(`${timestamp}-constructors.json`, constructors);
    createFile(`${timestamp}-racePoints.json`, racePoints);
    createFile('players.json', updatedPlayers);
    createFile('constructors.json', updatedConstructors);
    createFile('racePoints.json', defaultRacePoints);
  }

  function createFile(name, data) {
    const element = document.createElement("a");
    const textFile = new Blob([JSON.stringify(data)], {type: 'text/plain'}); //pass data from localStorage API to blob
    element.href = URL.createObjectURL(textFile);
    element.download = name;
    document.body.appendChild(element); 
    element.click();
    console.log(`Generated file ${name} with data ->`,data);
  }

  function triggerFile() {
    if (!canManageFiles) { return; }
    document.getElementById('input').click();
  }

  return (
    <div className="App">
      <header className="App-header">
        <Header handleSaveData={handleSaveData} triggerFile={triggerFile} canManageFiles={canManageFiles} />
        <p className="grid-switch font-bold" onClick={() => setPilotView(!pilotView)}>{ pilotView ? 'Pilotos' : 'Constructores'}</p>
        {
          pilotView ?
            <Classification playersWithPoints={playersWithPoints} />
            : <Constructors constructorsList={constructorsList} />
        }
      </header>
      <input type="file" id="input" hidden/>
    </div>
  );
}

export default App;
