export const defaultRacePoints = {
  "Rinta": null,
  "Servi": null,
  "Bruno": null,
  "Agresivo": null,
  "Papo": null,
  "Angel": null,
  "Jonathan": null,
  "Mati": null,
  "Perry": null,
  "Frantz": null,
  "Lucas": null,
  "Jusanic": null,
  "Xebec": null,
  "David": null,
  "Senpai": null,
  "Miguel": null,
  "Dyno": null,
  "Carfe": null,
  "Yago": null,
  "Kenshin": null
}
