import { useEffect, useState } from 'react';
import TeamRow from "../../components/teamRow/teamRow";

function Constructors(props) {
  const constructorsList = props.constructorsList;
  const [constructors, setConstructors] = useState([]);

  useEffect(() => {
    let listWithTeam = [];
    constructorsList.forEach((player, index) => {
      setTimeout(() => {
        if (player.name === listWithTeam[index]?.name) {
          listWithTeam[index] = player;
        } else {
          listWithTeam.push(player);
        }
        setConstructors([...listWithTeam]);
      }, 150 * index);
    });
  }, [constructorsList]);
  
  return (
    <div className="list-container">
      {constructors.map((constructor, index) => {
        const position = index + 1;
        return <TeamRow key={index}
          team={constructor.team}
          pos={position}
          points={constructor.points}
          currentPosition={constructor.currentPosition}
          calculated={constructor.calculated}
        />
      })}
    </div>
  )
}

export default Constructors;