import { teamClassesDictionary } from '../../config/dictionaries';
import './playerRow.css';

function PlayerRow(props) {
  const player = props.player;
  const position = props.pos;
  const points = props.points;
  const total = player.points + (points === 'DNF' || points === null ? 0 : points);
  const changePositionIndicator = points === null || !player.currentPosition
    ? 'none'
    : player.currentPosition > position
      ? 'up'
      : 'down';

  return (
    <div className="player-row font-bold">
      <div className="cell player-position">
        <p>{ position }</p>
      </div>
      <div className={`cell player-name ${teamClassesDictionary[player.team]} ${points !== null ? 'sm-player-name-wpoints' : ''}`}>
        <p>{ player.player }</p>
        <span></span>
      </div>
      {points !== null ? <div className={`cell points ${points === 'DNF' ? 'points-dnf' : ''}`}>
        <p>{ points && points !== 'DNF' ? `+${points}` : points }</p>
      </div> : null}
      <div className="cell total">
        <p>{ total }</p>
      </div>
      {changePositionIndicator !== 'none' ? <div className="change-position-indicator font-regular">
        {player.currentPosition !== position ? <>
          <span className={`indicator ${changePositionIndicator}-indicator`}>{'>'}</span>
          <p className="change-position-amount">
            {changePositionIndicator === 'up' ? `+${player.currentPosition - position}` : `-${position - player.currentPosition}`}
          </p>
        </>: null}
      </div> : null}
    </div>
  )
}

export default PlayerRow;
