import { useEffect, useState } from 'react';
import PlayerRow from '../../components/playerRow/playerRow';

import './Classification.css'

function Classification(props) {
  const playersWithPoints = props.playersWithPoints;
  const [playersFiltered, setPlayersFiltered] = useState([]);
  const [firstTen, setFirstTen] = useState(true);

  useEffect(() => {
    const filteredPlayers = playersWithPoints.filter((player, index) => ((firstTen && index < 10) || (!firstTen && index > 9)));
    let listWithPlayer = [];
    setPlayersFiltered([])
    filteredPlayers.forEach((player, index) => {
      setTimeout(() => {
        if (player.name === listWithPlayer[index]?.name) {
          listWithPlayer[index] = player;
        } else {
          listWithPlayer.push(player);
        }
        setPlayersFiltered([...listWithPlayer]);
      }, 200 * index);
    });
  }, [firstTen, playersWithPoints]);

  return (
    <div className="list-container">
      <div className="buttons-container">
        <button className="font-bold" onClick={() => setFirstTen(true)} disabled={firstTen}>{"<"}</button>
        <button className="font-bold" onClick={() => setFirstTen(false)} disabled={!firstTen}>{">"}</button>
      </div>
      {playersFiltered.map((player, index) => {
        const position = index + 1 + (firstTen ? 0 : 10);
        return <PlayerRow key={index} player={player} pos={position} points={player.racePoints} />
      })}
    </div>
  )
}

export default Classification;
