export const teamNamesDictionary = {
  rb: 'Red Bull',
  am: 'Aston Martin',
  ferr: 'Ferrari',
  at: 'Alpha Tauri',
  will: 'Williams',
  merc: 'Mercedes',
  haas: 'Haas',
  alp: 'Alpine',
  mcl: 'McLaren',
  ar: 'Alfa Romeo'
}

export const teamClassesDictionary = {
  rb: 'red-bull-row',
  am: 'aston-martin-row',
  ferr: 'ferrari-row',
  at: 'alpha-tauri-row',
  will: 'williams-row',
  merc: 'mercedes-row',
  haas: 'haas-row',
  alp: 'alpine-row',
  mcl: 'mclaren-row',
  ar: 'alfa-romeo-row'
}